import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { FirestoreDateTimePipe } from './pipe/firestore-date-time.pipe';
import { MasterDetailComponent } from './master-detail/master-detail.component';
import { FieldEditorComponent } from './master-detail/dynamic-form/field-editor/field-editor.component';
import { DynamicFormComponent } from './master-detail/dynamic-form/dynamic-form/dynamic-form.component';
import { DynamicFieldComponent } from './master-detail/dynamic-form/dynamic-field/dynamic-field.component';
import { DataTableComponent } from './master-detail/data-table/data-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableExporterModule } from 'mat-table-exporter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { getPtBRPaginatorIntl } from './ptbr-paginator-intl';
import { MaterialModule } from './material.module';
import { FieldControlService } from './master-detail/dynamic-form/field-control.service';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ImageCampanhaComponent } from './image-campanha/image-campanha.component';
import { FormatPipe } from './pipe/format.pipe';
import { FieldBoxComponent } from './field-box/field-box.component';
import { HttpClientModule } from '@angular/common/http';
import { ChipsAutoCompleteComponent } from './chips-auto-complete/chips-auto-complete.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { ChartsModule } from 'ng2-charts';
import { CepMaskDirective } from './directives/cep-mask';
import { EnterTabDirective } from './directives/enter-tab';
import { CpfCnpjMaskDirective } from './directives/cpf-cnpj-mask-directive';
import { NumberOrPercentDirective } from './directives/number-or-percent.directive';
import { InputTagDirective } from './directives/input-tag.directive';
import { RgMaskDirective } from './directives/rg-mask';
import { TelephoneMaskDirective } from './directives/telephone-mask-directive';
import { ToEndDirective } from './directives/toend.directive';
import { CurrencyPipe } from './pipe/currency.pipe';
import { TableFieldPipe } from './pipe/table-field.pipe';
import { UnixTimePipe } from './pipe/unix-time.pipe';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { CardsComponent } from './cards/dashboard-card.component';
import { RatingComponent } from './rating/rating.component';
import { FormCadastroComponent } from './form-cadastro/form-cadastro.component';
import { UppercaseDirective } from './directives/uppercase.directive';
import { QrCodeModule } from 'ng-qrcode';
import { FilterPipe } from './pipe/filter.pipe';
import { ChatComponent } from './chat/chat.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { NgxImageCompressService } from 'ngx-image-compress'; 

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
      validation: true,
  };
};

@NgModule({
  declarations: [
    FirestoreDateTimePipe,
    MasterDetailComponent,
    FieldEditorComponent,
    DynamicFormComponent,
    DynamicFieldComponent,
    DataTableComponent,
    ImageModalComponent,
    ImageCampanhaComponent,
    FormatPipe,
    FieldBoxComponent,
    ChipsAutoCompleteComponent,
    DialogConfirmationComponent,
    SafeHtmlPipe,
    CepMaskDirective,
    CpfCnpjMaskDirective,
    EnterTabDirective,
    NumberOrPercentDirective,
    InputTagDirective,
    RgMaskDirective,
    TelephoneMaskDirective,
    ToEndDirective,
    CurrencyPipe,
    TableFieldPipe,
    UnixTimePipe,
    FileUploadComponent,
    CardsComponent,
    RatingComponent,
    FormCadastroComponent,
    UppercaseDirective,
    FilterPipe,
    ChatComponent,
    FileDropDirective 
  ],
  imports: [
    AngularEditorModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialModule,
    MatTableExporterModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(maskConfigFunction),
    CurrencyMaskModule,
    NgxMaterialTimepickerModule,
    QrCodeModule
    // ChartsModule
  ],
  exports: [
    AngularEditorModule,
    FirestoreDateTimePipe,
    MasterDetailComponent,
    FieldEditorComponent,
    DynamicFieldComponent,
    DynamicFormComponent,
    DataTableComponent,
    FieldBoxComponent,
    FlexLayoutModule,
    FormatPipe,
    HttpClientModule,
    ImageModalComponent,
    ImageCampanhaComponent,
    MaterialModule,
    ChipsAutoCompleteComponent,
    DialogConfirmationComponent,
    SafeHtmlPipe,
    NgxSpinnerModule,
    CepMaskDirective,
    CpfCnpjMaskDirective,
    EnterTabDirective,
    NumberOrPercentDirective,
    InputTagDirective,
    RgMaskDirective,
    TelephoneMaskDirective,
    ToEndDirective,
    CurrencyPipe,
    TableFieldPipe,
    UnixTimePipe,
    CurrencyMaskModule,
    NgxMaterialTimepickerModule,
    FileUploadComponent,
    CardsComponent,
    RatingComponent,
    FormCadastroComponent,
    UppercaseDirective,
    QrCodeModule,
    FilterPipe,
    ChatComponent,
    FileDropDirective 
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
        ngModule: SharedModule,
        providers: [
          { provide: LOCALE_ID, useValue: 'pt-BR' },
          { provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
          { provide: MatPaginator, useValue: getPtBRPaginatorIntl()},
          FirestoreDateTimePipe,
          FormatPipe,
          FieldControlService,
          UnixTimePipe,
          NgxImageCompressService
        ]
    }
  }
}
