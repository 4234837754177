import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UiService } from '../services/ui-service';
import { collectionJoin } from '../shared/operators/collection.join';
import { Utils } from '../shared/utils';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private _user = new BehaviorSubject<any>(false);

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private http: HttpClient,
    private uiService: UiService
  ) {
    this.getUserLogged().subscribe();
  }

  get user() {
    return this._user.asObservable();
  }

  log(email, senha) {
    return this.afAuth.signInWithEmailAndPassword(email, senha);
  }

  getUserLogged() {
    let user = undefined;
    return this.afAuth.authState.pipe(
      switchMap((user_) => {
        if (user_) {
          return this.afs.doc(`users/${user_.uid}`).valueChanges();
        } else {
          return of(undefined);
        }
      }),
      first(),
      switchMap((_user: any) => {
        if (_user) {
          user = _user;
          if (_user.dataRef) {
            return this.afs.doc(_user.dataRef).valueChanges();
          } else {
            return of(undefined);
          }
        } else {
          return of(undefined);
        }
      }),
      first(),
      switchMap((_user: any) => {
        if (_user) {
          user.data = _user;
        }

        this._user.next(user);
        return of(user);
      })
    );
  }

  fetchFuncionarios() {
    return this.afAuth.authState.pipe(
      first(),
      switchMap((_user: any) => {
        return this.afs
          .collection('users', (ref) =>
            ref.where('isFuncFrom', '==', _user.uid)
          )
          .valueChanges();
      })
    );
  }

  saveFuncionario(obj) {
    return this.user.pipe(
      first(),
      switchMap((_user: any) => {
        return this.http.post(`${environment.googleApi}/createFuncionarios`, {
          nome: obj.name,
          email: obj.email,
          isBoy: false,
          cadastro_completo: true,
          ativo: true,
          idMain: _user.id,
          isAdmin: false,
          dataRef: _user.dataRef,
        });
      })
    );
  }

  recoverPassword(email) {
    return this.afs
      .collection('users', (ref) => ref.where('email', '==', email))
      .valueChanges()
      .pipe(
        first(),
        map((user) => {
          if (user.length > 0) return this.afAuth.sendPasswordResetEmail(email);
          throwError('Este endereço de email não está cadastrado');
        })
      );
  }

  async createUser(user) {
    let results: any = await this.afs
      .collection('users', (ref) => ref.where('email', '==', user.email))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    let canCreate = true;

    for (const r of results) {
      if (!r.inativo) {
        canCreate = false;
      }
    }

    if (canCreate) {
      return this.afAuth
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(() => {
          return this.afAuth.authState.pipe(
            first(),
            switchMap((_user: any) => {
              return this.afs
                .doc(`users/${_user.uid}`)
                .valueChanges()
                .pipe(
                  first(),
                  switchMap((result) => {
                    if (!result) {
                      const ref = this.afs
                        .collection('users')
                        .doc(_user.uid).ref;
                      return of(
                        ref.set({
                          email: _user.email,
                          id: _user.uid,
                          nome: user.name,
                          admin: false,
                          isBoy: user.isBoy,
                          ativo: false,
                          cod: null,
                          cadastro_completo: false,
                        })
                      );
                    }
                  })
                );
            })
          );
        });
    } else {
      return throwError({
        code: 'already',
        msg: 'Este endereço de email já está em uso',
      });
    }
  }

  async createUserAdmin(user) {
    let results: any = await this.afs
      .collection('users', (ref) => ref.where('email', '==', user.email))
      .valueChanges()
      .pipe(take(1))
      .toPromise();
    let canCreate = true;

    for (const r of results) {
      if (!r.inativo) {
        canCreate = false;
      }
    }

    if (canCreate) {
      return this.afAuth
        .createUserWithEmailAndPassword(user.email, user.password)
        .then(() => {
          return this.afAuth.authState.pipe(
            first(),
            switchMap((_user: any) => {
              return this.afs
                .doc(`users/${_user.uid}`)
                .valueChanges()
                .pipe(
                  first(),
                  switchMap((result) => {
                    if (!result) {
                      const ref = this.afs
                        .collection('users')
                        .doc(_user.uid).ref;
                      this.uiService.showSwal(
                        'Cadastro realizado com sucesso!',
                        '',
                        false,
                        'success'
                      );
                      return of(
                        ref.set({
                          email: _user.email,
                          id: _user.uid,
                          nome: user.name,
                          admin: true,
                          isBoy: false,
                          ativo: true,
                          cod: null,
                          cadastro_completo: true,
                          nivel: user.nivel,
                          pass: user.password,
                        })
                      );
                    }
                  })
                );
            })
          );
        });
    } else {
      return throwError({
        code: 'already',
        msg: 'Este endereço de email já está em uso',
      });
    }
  }

  fetchAllBoys() {
    return this.afs
      .collection('users', (ref) => ref.where('isBoy', '==', true))
      .valueChanges();
  }

  logout() {
    this.router.navigate(['login']);
    this._user.next(false);
    this.afAuth.signOut();
  }

  generateId() {
    return this.afs.createId();
  }
}
