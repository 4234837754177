<mat-tab-group #tabs>
  <mat-tab>
    <div class="container">
      <div class="container-inside">
        <img src="assets/boygo_logo_png_black.png" height="200">
        <div class="login-card">
          <h2 mat-subheader>Realize seu login</h2>
          <div class="form-group">
            <form [formGroup]="loginForm">
              <mat-form-field appearance="outline" class="login-card-fields">
                <input matInput formControlName="email" placeholder="Informe o email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="login-card-fields">
                <input matInput formControlName="password" placeholder="Informe a Senha"
                  [type]="hidePassword1 ? 'password' : 'text'">
                <span matSuffix class="material-icons"
                  (click)="hidePassword1 = !hidePassword1">{{hidePassword1 ? 'visibility_off' : 'visibility'}}</span>
              </mat-form-field>
            </form>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="card-actions">
            <button #acessar mat-raised-button color="primary" fxFlex="45" (click)="login()"
              [disabled]="!loginForm.valid || showingProgressBar">Avançar</button>
            <button mat-button (click)="esqueciSenha()" color="primary">Esqueci minha senha</button>
          </div>
        </div>
        <div>
          <button (click)="doRegister()" mat-stroked-button>Novo por aqui? Faça agora seu cadastro
            <mat-icon>east</mat-icon>
          </button>
        </div>
      </div>
    </div>  
  </mat-tab>

  <mat-tab>
    <div class="container">
      <div class="container-inside">
        <img src="assets/boygo_logo_png_black.png" height="200">
        <div class="register-card">
          <button style="align-self: flex-start" (click)="backToLogin()" mat-button color="accent">
            <mat-icon>west</mat-icon> Voltar para login
          </button>
          <h2 mat-subheader>Registre-se</h2>
          <div fxLayout="column">
            <form [formGroup]="createForm">
              <mat-form-field appearance="outline" class="login-card-fields">
                <input matInput formControlName="name" placeholder="Informe o Nome Completo/Razão Social">
              </mat-form-field>
              <mat-form-field appearance="outline" class="login-card-fields">
                <input matInput formControlName="email" placeholder="Informe o Email">
              </mat-form-field>
              <div [fxLayout]="(isMobile$ | async) ? 'column' : 'row'" class="login-card-fields" fxLayoutAlign="space-between stretch">
                <div [fxFlex]="(isMobile$ | async) ? '100' : '48'">
                  <mat-form-field appearance="outline" class="login-card-fields">
                    <input matInput formControlName="password" placeholder="Informe a Senha"
                      [type]="hidePassword1 ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hidePassword1 = !hidePassword1">
                      {{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error>A senha precisa ter no mínimo 6 caracteres</mat-error>
                  </mat-form-field>
                </div>
                <div [fxFlex]="(isMobile$ | async) ? '100' : '48'">
                  <mat-form-field appearance="outline" class="login-card-fields">
                    <input matInput formControlName="verify" placeholder="Confirme a senha"
                      [type]="hidePassword2 ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hidePassword2 = !hidePassword2">
                      {{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="!samePassword">Senhas não coincidem</mat-error>
                  </mat-form-field>
                </div>
              </div>

              <mat-radio-group formControlName="isBoy" aria-label="Select an option">
                <mat-radio-button [value]="false">Empresa</mat-radio-button><br>
                <mat-radio-button [value]="true">Boy</mat-radio-button>
              </mat-radio-group>
            </form>
          </div>
        </div>

        <br>
        <button #acessar style="width: 40% !important;" mat-raised-button color="primary"
          (click)="create()" [disabled]="!createForm.valid || showingProgressBar">Criar</button>
      </div>
    </div>
  </mat-tab>

  <!-- <mat-tab>
    <div [fxLayout]="(isMobile$ | async) ? 'row' : 'column'" fxLayoutAlign="center" fxFlex="100"
      class="container mat-elevation-z4">
      <div [fxLayout]="(isMobile$ | async) ? 'column' : 'row'" fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="45">
          <img src="assets/boygo_logo_png_black.png" height="200">
          <div class="login-card">
            <button (click)="backToLogin()" mat-button color="accent">
              <mat-icon>west</mat-icon> Voltar para login
            </button>
            <h2 mat-subheader>Registre-se</h2>
            <form [formGroup]="createForm">
              <div fxLayout="column">
                <mat-form-field appearance="outline" class="login-card-fields">
                  <input matInput formControlName="name" placeholder="Informe o Nome Completo/Razão Social">
                </mat-form-field>
                <mat-form-field appearance="outline" class="login-card-fields">
                  <input matInput formControlName="email" placeholder="Informe o Email">
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div fxFlex="48">
                    <mat-form-field appearance="outline" class="login-card-fields">
                      <input matInput formControlName="password" placeholder="Informe a Senha"
                        [type]="hidePassword1 ? 'password' : 'text'">
                      <mat-icon matSuffix (click)="hidePassword1 = !hidePassword1">
                        {{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error>A senha precisa ter no mínimo 6 caracteres</mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="48">
                    <mat-form-field appearance="outline" class="login-card-fields">
                      <input matInput formControlName="verify" placeholder="Confirme a senha"
                        [type]="hidePassword2 ? 'password' : 'text'">
                      <mat-icon matSuffix (click)="hidePassword2 = !hidePassword2">
                        {{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                      <mat-error *ngIf="!samePassword">Senhas não coincidem</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <mat-radio-group formControlName="isBoy" aria-label="Select an option">
                  <mat-radio-button [value]="false">Empresa</mat-radio-button><br>
                  <mat-radio-button [value]="true">Boy</mat-radio-button>
                </mat-radio-group>
              </div>
            </form>
            <button #acessar style="width: 40% !important;" fxFlexOffset="2.5" mat-raised-button color="primary"
              (click)="create()" [disabled]="!createForm.valid || showingProgressBar">Criar</button>
          </div>

        </div>
      </div>
    </div>
  </mat-tab> -->

  <mat-tab>
    <div [fxLayout]="(isMobile$ | async) ? 'row' : 'column'" fxLayoutAlign="center" fxFlex="100"
      class="container mat-elevation-z4">
      <div [fxLayout]="(isMobile$ | async) ? 'column' : 'row'" fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="45">
          <img src="assets/boygo_logo_png_black.png" height="200">
          <div class="login-card">
            <button (click)="backToLogin()" mat-button color="accent">
              <mat-icon>west</mat-icon> Voltar para login
            </button>
            <h2 mat-subheader>Recupere sua senha</h2>
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="login-card-fields">
                <input matInput [(ngModel)]="retrieveEmail" placeholder="Informe o email cadastrado">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="card-actions">
              <button mat-raised-button color="primary" fxFlex="45" (click)="sendEmail()"
                [disabled]="!retrieveEmail || retrieveEmail.length == 0">Enviar email</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
