import { Pipe, PipeTransform } from '@angular/core';

var VMasker = require('vanilla-masker')

export const CPF_FORMAT = '999.999.999-99'
export const CNPJ_FORMAT = '99.999.999/9999-99'

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: string, format?: string): unknown {
    if ( value && value.trim() != '' ) {
      return VMasker.toPattern(value, (format ? format : (value.trim().length > 11 ? CNPJ_FORMAT : CPF_FORMAT)));
    }

    return value
  }

}
