// import { DialogConfirmationData, DialogConfirmationComponent } from './../shared/dialog-confirmation/dialog-confirmation.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable, Inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';
import { Color } from '@angular-material-components/color-picker';
import Swal from 'sweetalert2';

declare const tinycolor: any;

export interface IColor {
  name: string,
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class UiService {
  private _showingProgressBar = new BehaviorSubject<boolean>(false)
  private _mobile = new BehaviorSubject<boolean>(false)
  private _primaryColor = new BehaviorSubject<string>(environment.defPrimaryColor)
  private _secondaryColor = new BehaviorSubject<string>(environment.defSecondaryColor)
  private _warningColor = new BehaviorSubject<string>(environment.defWarningColor)

  private primaryColorPallete: IColor[] = []
  private secondaryColorPallete: IColor[] = []
  private warningColorPallete: IColor[] = []

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private document: Document) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ])
      .pipe(take(1))
      .subscribe(result => {
        this._mobile.next(result.matches)
      })
  }

  get showingProgressBar() {
    return this._showingProgressBar.asObservable()
  }

  get isMobile() {
    return this._mobile.asObservable()
  }

  get primaryColor() {
    return this._primaryColor.asObservable()
  }

  get secondaryColor() {
    return this._secondaryColor.asObservable()
  }

  getPrimaryColorRGB() {
    return this.hexToRgb(this._primaryColor.value)
  }

  getSecondaryColorRGB() {
    return this.hexToRgb(this._secondaryColor.value)
  }

  showProgressBar(show: boolean) {
    this._showingProgressBar.next(show)
  }

  setPrimaryColor(color: string) {
    this._primaryColor.next(color)
    this.applyPrimaryColor()
  }

  applyPrimaryColor() {
    this.primaryColorPallete = this.computeColors(this._primaryColor.value)
    this.applyColorPalette('primary', this.primaryColorPallete)
  }

  setSecondaryColor(color: string) {
    this._secondaryColor.next(color)
    this.applySecondaryColor()
  }

  applySecondaryColor() {
    this.secondaryColorPallete = this.computeColors(this._secondaryColor.value)
    this.applyColorPalette('secondary', this.secondaryColorPallete)
  }

  warningColor(color: string) {
    this._warningColor.next(color)
    this.applyWarningColor()
  }

  applyWarningColor() {
    this.secondaryColorPallete = this.computeColors(this._warningColor.value)
    this.applyColorPalette('warning', this.warningColorPallete)
  }

  applyAllColors() {
    this.applyPrimaryColor()
    this.applySecondaryColor()
    this.applyWarningColor()
  }

  applyColorPalette(paletteName: string, palette: IColor[]) {
    let key = `--theme-${paletteName}-`
    let keyContrast = `--theme-${paletteName}-contrast`

    palette.forEach(color => {
      const _key = `${key}${color.name}`
      const value = color.hex
      const _keyContrast = `${keyContrast}${color.name}`
      const valueContrast = color.darkContrast ? 'rgba(black, 0.87)' : 'white'

      this.document.documentElement.style.setProperty(_key, value)
      this.document.documentElement.style.setProperty(_keyContrast, valueContrast)
    });
  }

  private computeColors(hexColor: string): IColor[] {
    return [
      this.getColorObject(tinycolor(hexColor).lighten(52), '50'),
      this.getColorObject(tinycolor(hexColor).lighten(37), '100'),
      this.getColorObject(tinycolor(hexColor).lighten(26), '200'),
      this.getColorObject(tinycolor(hexColor).lighten(12), '300'),
      this.getColorObject(tinycolor(hexColor).lighten(6), '400'),
      this.getColorObject(tinycolor(hexColor), '500'),
      this.getColorObject(tinycolor(hexColor).darken(6), '600'),
      this.getColorObject(tinycolor(hexColor).darken(12), '700'),
      this.getColorObject(tinycolor(hexColor).darken(18), '800'),
      this.getColorObject(tinycolor(hexColor).darken(24), '900'),
      this.getColorObject(tinycolor(hexColor).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hexColor).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hexColor).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hexColor).lighten(5).saturate(5), 'A700')
    ]
  }

  private getColorObject(value, name): IColor {
    const c = tinycolor(value);
    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    }
  }

  hexToRgb(hexColor: string) {
    if (hexColor.charAt(0) === '#') {
      hexColor = hexColor.substr(1)
    }

    if (hexColor.length < 2 || hexColor.length > 6) {
      return false;
    }

    let values = hexColor.split('')
    let r, g, b

    if (hexColor.length === 2) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = r;
      b = r;
    } else if (hexColor.length === 3) {
      r = parseInt(values[0].toString() + values[0].toString(), 16);
      g = parseInt(values[1].toString() + values[1].toString(), 16);
      b = parseInt(values[2].toString() + values[2].toString(), 16);
    } else if (hexColor.length === 6) {
      r = parseInt(values[0].toString() + values[1].toString(), 16);
      g = parseInt(values[2].toString() + values[3].toString(), 16);
      b = parseInt(values[4].toString() + values[5].toString(), 16);
    } else {
      return false;
    }

    return [r, g, b];
  }

  hexToColor(hex: string): Color {
    let rgb = this.hexToRgb(hex)
    let color = new Color(rgb[0], rgb[1], rgb[2], 1)
    return color
  }

  showSnackBar(message, button = 'OK') {
    return this.snackBar.open(message, button, { duration: 4000 })
  }

  showSwal(title, text, loading = false, icon) {
    if (loading) {
      Swal.fire({
        title: title,
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: 'btn-swal',
          popup: 'popup-swal',
          htmlContainer: 'text-swal',
          title: 'text-swal',
        },
        buttonsStyling: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })
    } else {
      Swal.fire({
        icon: icon,
        title: title,
        text: text,
        buttonsStyling: false,
        customClass: {
          popup: 'popup-swal',
          htmlContainer: 'text-swal',
          title: 'text-swal',
          confirmButton: 'btn-swal',
        },
      })
    }
  }


  showConfigSwal(title, text, icon) {
   return Swal.fire({
      icon: icon,
      title: title,
      text: text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#659418',
      denyButtonText: 'Não',
      customClass: {
        confirmButton: 'btn-swal',
        popup: 'popup-swal',
        htmlContainer: 'text-swal',
        title: 'text-swal',
      }
    }).then((result) => { 
      if (result.isConfirmed) {
        Swal.fire('Operação efetuado com sucesso!', '', 'success')
        return true
      } else if (result.isDenied) {
        return false
      }
    })
  }

  closeSwal() {
    Swal.close()
  }

  // showDialog(data: Partial<DialogConfirmationData>): MatDialogRef<DialogConfirmationData> {
  //   return this.dialog.open(DialogConfirmationComponent, {data})
  // }
}
