export const environment = {
  production: true,
  defPrimaryColor: '#94ba3a',
  defSecondaryColor: '#000',
  defWarningColor: '#e0314b',
  apiUrl: 'https://api.boygo.clickmix.com.br/v1',
  firebaseConfig: {
    apiKey: "AIzaSyDhDnpQpkC0bxoffWMvbmnzG4CRRBVUZ2k",
    authDomain: "boygo-dev-d3587.firebaseapp.com",
    databaseURL: "https://boygo-dev-d3587-default-rtdb.firebaseio.com",
    projectId: "boygo-dev-d3587",
    storageBucket: "boygo-dev-d3587.appspot.com",
    messagingSenderId: "126500542983",
    appId: "1:126500542983:web:c7a27801a78871d6aba8a2"
  },
  // firebaseConfig: {
  //   apiKey: "AIzaSyBZmwsvEdTwJPEExtE9cdVADDrfrM6u0tg",
  //   authDomain: "boygo-dev.firebaseapp.com",
  //   projectId: "boygo-dev",
  //   storageBucket: "boygo-dev.appspot.com",
  //   messagingSenderId: "341812985728",
  //   appId: "1:341812985728:web:5039309adb401387e28b65"
  // },
  googleApi: "https://us-central1-boygo-dev-d3587.cloudfunctions.net",
  // googleApi: "https://us-central1-boygo-dev.cloudfunctions.net",
  cepApi: "https://brasilapi.com.br",
  googleKey: "AIzaSyCiAUHRDzqAUNhfcK5kiM7dxIdsqOFHVbg",
  pjBankUrl: 'https://us-central1-boygo-dev-d3587.cloudfunctions.net',
  pjBank: 'https://api.pjbank.com.br',
  credential: "68804d2dd86c14fb10f3b00dc804239c169bf6aa",
  chave: "fac9ed315902580ea6eec20688995eba887c84d5",
  ibgeUrl: 'https://brasilapi.com.br/api',
};
