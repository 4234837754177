import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } 
    from '@angular/router';
import { Subscription } from "rxjs";
import { first, map, switchMap, take } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private loginService: LoginService ,
        private afs: AngularFirestore,
    ) { }
    
    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) 
    {
        return this.isAuthenticated(state.url)
    }

    isAuthenticated(path) {
        let subs = new Subscription();
        subs.add(this.loginService.getUserLogged().subscribe())
        
        return this.afAuth.authState.pipe(
            switchMap(user_ => {
                if(!user_){
                    subs.unsubscribe()
                    this.router.navigateByUrl('login')
                }

                return this.afs.doc(`users/${user_.uid}`).valueChanges()    
            }),
            take(1),
            map( (user: any) => {
                if(!user.admin){
                    subs.unsubscribe()
                    this.router.navigateByUrl('dashboard')
                }
                
                subs.unsubscribe()
                return true
            })
        )
    }

}