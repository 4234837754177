<div>
  <mat-toolbar class="mat-elevation-z2">
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center">
      <a [routerLink]="['']"><img src="assets/boygo_logo_png_white.png" height="70px"></a>
      <mat-divider [inset]="true" [vertical]="true"></mat-divider>
      <div fxLayout="column" fxLayoutAlign="center center">
        <span style="font-size: 18pt;" class="toolbar-title"></span>
      </div>
    </div>

    <span class="spacer"></span>
    <div fxLayout="row" fxLayoutGap="4px" *ngIf="user$ | async; let user">
      <div fxShow="true" fxHide.lt-md="true" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center">
        <ng-container *ngIf="!user.admin">
          <ng-container *ngIf="user.cadastro_completo">
            <button color="primary" [routerLink]="['/']" mat-button>Início</button>
            <button color="primary" [routerLink]="['/viagens']" mat-button>Viagens</button>
            <ng-container *ngIf="!user?.isFunc">
              <button color="primary" [routerLink]="['/carteira']" mat-button>Financeiro</button>
              <button color="primary" [routerLink]="['/dados']" mat-button>Meus Dados</button>
            </ng-container>
            <button color="primary" [routerLink]="['/create']" mat-flat-button>Nova Viagem</button>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="user.admin">
          <button color="primary" [routerLink]="['/admin/']" mat-button>Início</button>
          <button color="primary" [routerLink]="['/admin/viagens']" mat-button>Viagens</button>
          <button color="primary" [routerLink]="['/admin/creditos']" mat-button>Créditos</button>
          <button color="primary" [routerLink]="['/admin/lancamentos']" mat-button>Lançamentos</button>
          <!-- <button color="primary" [routerLink]="['/admin/loja']" mat-button>Loja</button> -->
          <button color="primary" [routerLink]="['/admin/boys']" mat-button>Entregadores</button>
          <button color="primary" [routerLink]="['/admin/empresas']" mat-button>Empresas</button>
          <button color="primary" [routerLink]="['/admin/pagamentos']" mat-button>Pagamentos</button>
          <button color="primary" [routerLink]="['/admin/suporte']" mat-button>Suporte</button>
          <button color="primary" [routerLink]="['/admin/loja']" mat-button>Loja</button>
          <button color="primary" [routerLink]="['/admin/configuracoes']" mat-button>Configurações</button>
        </ng-container>
      </div>
      <div #responsive>
        <button mat-icon-button (click)="m.toggle()" fxShow="true" fxHide.gt-sm [matMenuTriggerFor]="m" id="responsive">
          <mat-icon>arrow_drop_down_circle</mat-icon>
        </button>
      </div>
      <mat-menu class="menu" #m="matMenu">
        <ng-container *ngIf="user.admin">
          <button color="primary" [routerLink]="['/admin/']" mat-menu-item>
            <mat-icon>home</mat-icon>Início
          </button>
          <button color="primary" [routerLink]="['/admin/viagens']" mat-menu-item>
            <mat-icon> pin_drop</mat-icon>Viagens
          </button>
          <button color="primary" [routerLink]="['/admin/creditos']" mat-menu-item>
            <mat-icon> local_atm</mat-icon>Créditos
          </button>
          <button color="primary" [routerLink]="['/admin/boys']" mat-menu-item>
            <mat-icon> person_pin_circle</mat-icon>Boys
          </button>
          <button color="primary" [routerLink]="['/admin/empresas']" mat-menu-item>
            <mat-icon>store_mall_directory</mat-icon>Empresas
          </button>
          <button color="primary" [routerLink]="['/admin/pagamentos']" mat-menu-item>
            <mat-icon> attach_money</mat-icon>Pagamentos
          </button>
          <button color="primary" [routerLink]="['/admin/suporte']" mat-menu-item>
            <mat-icon>contact_support</mat-icon>Suporte
          </button>
          <button color="primary" [routerLink]="['/admin/configuracoes']" mat-menu-item>
            <mat-icon>settings</mat-icon>Configurações
          </button>
        </ng-container>

        <ng-container *ngIf="!user.admin">
          <button color="primary" [routerLink]="['/']" mat-menu-item>
            <mat-icon>home</mat-icon>Início
          </button>
          <button color="primary" [routerLink]="['/viagens']" mat-menu-item>
            <mat-icon> pin_drop</mat-icon>Viagens
          </button>
          <button color="primary" [routerLink]="['/carteira']" mat-menu-item>
            <mat-icon> local_atm</mat-icon>Financeiro
          </button>
          <button color="primary" [routerLink]="['/dados']" mat-menu-item>
            <mat-icon> contacts</mat-icon>Meus Dados
          </button>
        </ng-container>
      </mat-menu>

      <div #profileButton>
        <button mat-stroked-button class="stroked-avatar" [matMenuTriggerFor]="menu">
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
            <div [matBadge]="notifications" matBadgeColor="warn" matBadgePosition="before"
              [matBadgeHidden]="!showNotificationsBadge || !(notifications > 0)" class="user-image"
              style="background-image: url('{{getImage(user)}}')"></div>
            <span>{{ user?.nome }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </button>
      </div>
      <mat-menu [panelClass]="'menu'" class="menu" #menu="matMenu">
        <button *ngIf="!user.admin" mat-menu-item routerLink="/suporte">
          <mat-icon>contact_support</mat-icon>
          <span>Contatar Suporte</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Sair</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</div>

<div *ngIf="user && !user?.ativo" fxLayout="row" style="margin-top: 65px;" fxLayoutAlign="center center"
  fxLayoutGap="8px">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="alert-container">
    <div>Seu cadastro está em análise, enquanto isso você pode navegar pelo sistema, entretanto não conseguirá
      criar/aceitar pedidos ou adicionar/sacar saldo</div>
  </div>
</div>

<router-outlet></router-outlet>