import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CadastroComponent } from './cadastro/cadastro.component';
import { AdminGuard } from './login/admin.guard';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login/login.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  
  { path: 'login', component: LoginComponent },
  { path: 'login/cadastro', component: LoginComponent },
  { path: 'cadastro', component: LoginComponent },

  { 
    path: 'home', 
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'viagens', 
    loadChildren: () => import('./viagens/viagens.module').then( m => m.ViagensModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'create', 
    loadChildren: () => import('./create-viagens/create-viagens.module').then( m => m.CreateViagensModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'carteira', 
    loadChildren: () => import('./carteira/carteira.module').then( m => m.CarteiraModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'dados', 
    loadChildren: () => import('./meus-dados/meus-dados.module').then( m => m.MeusDadosModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'suporte', 
    loadChildren: () => import('./suporte/suporte.module').then( m => m.SuporteModule ), 
    canActivate: [LoginGuard]
  },
  { 
    path: 'finalize', 
    loadChildren: () => import('./finalize-cadastro/finalize-cadastro.module').then( m => m.FinalizeCadastroModule ), 
  },
  { 
    path: 'admin', 
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule ), 
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
