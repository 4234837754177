import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot }
    from '@angular/router';
import { of, Subscription } from "rxjs";
import { map, switchMap, take } from 'rxjs/operators';
import { LoginService } from './login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private loginService: LoginService,
        private afs: AngularFirestore,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot)
    {
        return this.isAuthenticated(state.url)
    }

    isAuthenticated(path) {
        let subs = new Subscription();
        subs.add(this.loginService.getUserLogged().subscribe())

        return this.afAuth.authState.pipe(
            switchMap(user_ => {
                if(!user_){
                    this.router.navigateByUrl('login')
                    subs.unsubscribe()
                }

                return this.afs.doc(`users/${user_.uid}`).valueChanges()
            }),
            take(1),
            map( (user: any) => {
                if(user.admin){
                    this.router.navigateByUrl('admin')
                    subs.unsubscribe()
                }

                if(!user.cadastro_completo){
                    this.router.navigateByUrl('finalize')
                    subs.unsubscribe()
                }

                let path_array =  path.split('/')

                if(user.isFunc && (path_array[1] != 'home' && path_array[1] != 'viagens' && path_array[1] != 'suporte')){
                    this.router.navigateByUrl('home')
                    subs.unsubscribe()
                }

                subs.unsubscribe()
                return true
            })
        )
    }

}
