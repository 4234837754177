import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import  firestore  from 'firebase/compat/app';
import  firebase  from 'firebase/compat/app';
import Timestamp = firebase.firestore.Timestamp;
import { formatDate } from '@angular/common';

import * as moment from 'moment';

@Pipe({
  name: 'firestoreDateTime'
})
export class FirestoreDateTimePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string ) { }

  transform(value: Timestamp, format?: string): any {
    if ( ! value || !value.toDate ) {
      return;
    }
    moment.locale('pt-BR')
    return moment(value.toDate()).format(format)
    // return formatDate(value.toDate(), format || 'medium', this.locale);
  }
}
