import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DestroySubscribers } from 'ngx-destroy-subscribers';
import { Observable, Unsubscribable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LoginService } from '../login/login.service'; 
import { Utils } from '../shared/utils';
@DestroySubscribers()

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('profileButton') profileButton: ElementRef;
  @ViewChild('responsive') responsive: ElementRef;

  user$: Observable<any>
  notifications: number = 0
  showNotificationsBadge: boolean = true

  private subscriber: Unsubscribable

  menuWidth: number = 120
  user

  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
  ) { }

  ngOnDestroy() { }

  ngAfterViewInit(): void {    
    this.subscriber = this.user$.subscribe((user) => {
      this.user = user
      if ( this.profileButton ) {
        this.menuWidth = this.profileButton.nativeElement.offsetWidth;
      }
      if ( this.responsive ) {
        this.menuWidth = this.responsive.nativeElement.offsetWidth;
      }
    })
  }

  ngOnInit() {
    this.user$ = this.loginService.user
  }

  logout() {
    this.loginService.logout()
  }

  getImage(user){
    return (!user?.data?.logo && !user?.data?.foto) ? 'assets/avatar.png' : (user?.data.logo ? user?.data.logo : user?.data.foto)
  }
}
