<app-main></app-main>

<ngx-spinner
    name="global"
    bdColor = "rgba(0,0,0,0.8)" 
    size = "large" 
    color = "#fff" 
    type = "ball-atom" 
    [fullScreen] = "true">
    <p style="color: #b9ff46" > Aguarde... </p>
</ngx-spinner>