import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterStateSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Unsubscribable } from 'rxjs';
import { finalize, take, map, debounceTime, first } from 'rxjs/operators';
import { UiService } from '../services/ui-service';
import { LoginService } from './login.service';
import { MatTabGroup } from '@angular/material/tabs';
import { DestroySubscribers } from 'ngx-destroy-subscribers';
@DestroySubscribers()

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  @ViewChild(MatTabGroup, {static: true}) tabs: MatTabGroup;
  
  loginForm: FormGroup
  createForm: FormGroup

  hidePassword1 = true
  hidePassword2 = true
  showingProgressBar = false
  redirectPath = ''
  type

  private subscriber: Unsubscribable

  isMobile$: Observable<boolean>

  samePassword = true

  retrieveEmail

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private uiService: UiService,
    private spinner: NgxSpinnerService,
  ) { 
    this.isMobile$ = this.uiService.isMobile
  }

  ngOnDestroy() { }

  ngOnInit(): void {
    let url = this.router.url.split('/')

    if(url.indexOf('cadastro') != -1){
      this.tabs.selectedIndex = 1
    }

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })

    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      verify: ['', Validators.required],
      isBoy: [null, Validators.required]
    })

    this.subscriber = this.createForm.get('verify').valueChanges.pipe(debounceTime(500)).subscribe(val => {
      if(!val){
        this.samePassword = false
        this.createForm.controls['verify'].setErrors({'As senhas não coincidem': true});
      }
      else{
        if(this.createForm.get('password').value == val){
          this.samePassword = true
        } else{
          this.samePassword = false
          this.createForm.controls['verify'].setErrors({'As senhas não coincidem': true});
        }
      }
    })
  }

  login() {
    this.spinner.show('global');
    this.loginService.log(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.subscriber = this.loginService.getUserLogged().subscribe((data)=> {
          this.spinner.hide('global')
          this.router.navigate(['/','home'])
        })
      })
      .catch((error) => {
        this.spinner.hide('global')
        this.uiService.showSwal('Erro ao logar', 'Credenciais incorretas e/ou email inexistente', false, 'error')
      })
  }
  
  create(){
    this.spinner.show('global');
    this.loginService.createUser(this.createForm.value).then(data => {
      this.subscriber = data.subscribe(d => {
        d.then((cadastro) => {
          this.subscriber = this.loginService.getUserLogged().subscribe(()=> {
            this.spinner.hide('global')
            this.router.navigate(['/','home'])
          })
        })
        .catch((error) => {
          this.spinner.hide('global')
          this.uiService.showSwal('Erro ao criar conta', error.msg, false, 'error')
        })
      }, (err) => {
        this.spinner.hide('global')
        this.uiService.showSwal('Erro ao criar conta', err.msg, false, 'error')
      })
    }).catch((error) => {
      this.spinner.hide('global')
      this.uiService.showSwal('Erro ao criar conta', error.msg, false, 'error')
    })
  }

  sendEmail(){
    this.subscriber = this.loginService.recoverPassword(this.retrieveEmail).subscribe(data => {
      data.then( () => {
        this.uiService.showSwal('Email enviado', 'Um link de recuperação foi enviado para o email informado, siga as intruções para recuperar a senha', false, 'success')
      })
    }, (error) => {
      this.uiService.showSwal('Erro ao enviar email', error.msg, false, 'error')
    })
  }

  doRegister() {
    this.tabs.selectedIndex = 1
  }

  backToLogin() {
    this.tabs.selectedIndex = 0
  }

  esqueciSenha(){
    this.tabs.selectedIndex = 2
  }
}
